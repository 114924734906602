import React, { useEffect, useRef, useState } from 'react';
import PubSub from 'pubsub-js';
import { CameraIcon, CheckIcon, CrossIcon, ErrorMessage } from 'retail-ui-library';

import './index.scss';

const Camera = ({ capturedImage }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [error, setError] = useState('');
  const [showControls, setShowControls] = useState(false);

  const setCanvasSize = () => {
    if (videoRef.current && canvasRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
    }
  };

  useEffect(() => {
    startCamera();

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('loadedmetadata', setCanvasSize);
      }
    };
  }, []);

  useEffect(() => {
    PubSub.subscribe('camera.start', () => {
      startCamera();
    });

    return () => {
      PubSub.unsubscribe('camera.start');
    };
  }, []);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment',
        },
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.addEventListener('loadedmetadata', setCanvasSize);
      }
    }
    catch (error: any) {
      setError(error);
      console.error('Error accessing camera:', error.name, error.message);
    }
  };

  const takePicture = () => {
    if (!videoRef.current?.srcObject) {
      console.error('Camera not started or permission denied.');
      return;
    }

    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext('2d');

      if (context) {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        const image = canvas.toDataURL('image/png');
        PubSub.publish('camera.capture.image', image);
        setShowControls(true);
      }
    }
  };

  const onAcceptedImage = () => {
    PubSub.publish('camera.capture.accept.image', true);
    setShowControls(false);
  };

  const onDeleteImage = () => {
    PubSub.publish('camera.capture.image', '');
    PubSub.publish('camera.capture.accept.image', false);
    startCamera();
  };

  return (
    <div className='camera' data-test-id='camera'>
      {capturedImage && (
        <div className='camera-captured-image-container'>
          <img
            alt='Captured'
            className='camera-captured-image'
            data-test-id='camera-captured-image'
            src={capturedImage}
          />

          {showControls && (
            <div className='camera-captured-image-controls'>
              <div>Is this image ok?</div>

              <CheckIcon
                className='camera-captured-image-controls-button-yes'
                dataTestId='image-control-yes'
                onClick={onAcceptedImage}
              />

              <CrossIcon
                className='camera-captured-image-controls-button-no'
                dataTestId='image-control-no'
                onClick={onDeleteImage}
              />
            </div>
          )}
        </div>
      )}

      {!capturedImage && (
        <>
          <div className='camera-preview-image-container'>
            <video
              autoPlay
              className='camera-preview-image'
              data-test-id='camera-preview-image'
              muted
              playsInline
              ref={videoRef}
            />
          </div>

          <div className='camera-note'>Press button to take a picture</div>

          <div className='camera-buttons'>
            <div
              className='camera-take-picture-button'
              data-test-id='camera-take-picture-button'
              onClick={takePicture}
            >
              <CameraIcon />
            </div>
          </div>

          <canvas
            ref={canvasRef}
            style={{
              display: 'none',
            }}
          />
        </>
      )}

      {error && <ErrorMessage message={error} />}

    </div>
  );
};

type Props = {
  capturedImage?: string;
}

export default Camera;