import { logger } from '../logger';

const request = async (url: string, { method, body, headers }: RequestInit, responseType = '') => {
  logger.log(`Making a ${method} request to: ${url}`);

  const headersToUse = headers && ('Content-Type' in headers) ? headers : {
    ...headers,
    'Content-Type': 'application/json',
  };

  const response = await fetch(url, {
    method,
    body,
    headers: headersToUse,
  });

  const { status: statusCode, statusText, ok } = response;

  const data = responseType === 'text' ?
    await response.text() :
    await response.json();

  if (!ok) {
    throw new Error(`Failed to fetch: ${statusCode} - ${statusText}`);
  }

  return {
    data,
    statusCode,
  };
};

const get = async (url: string, options?: RequestInit, responseType?: 'text' | 'json') => {
  return await request(url, {
    ...options,
    method: 'GET',
  }, responseType);
};

const put = async (url: string, options?: RequestInit, responseType?: 'text' | 'json') => {
  return await request(url, {
    ...options,
    method: 'PUT',
  }, responseType);
};

export const http = {
  get,
  put,
};