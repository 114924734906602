import * as dateFns from 'date-fns';

const constructFilename = (filename: string, shopId: string): string => {
  return `${shopId}/${filename}-${dateFns.format(new Date(), 'dd-MM-yyyy-HH:mma')}.png`;
};

const convertImageDataToBlob = async (base64String: string, validFileType: string) => {
  const blob = await (await fetch(base64String)).blob();

  if (!blob || blob.type !== validFileType) {
    throw new Error('Could not generate a valid file from image data');
  }

  return blob;
};

export const image = {
  constructFilename,
  convertImageDataToBlob,
};