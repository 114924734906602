export const isValidShopId = (id: string) => /^\d{6}$/.test(id);

export const isValidFilename = (filename: string) => {
  const invalidCharsRegex = /[\/\\:*?"<>|]/;
  const consecutiveDotsRegex = /\.{2,}/;
  const startEndDotRegex = /^\./;

  if (
    invalidCharsRegex.test(filename) ||
    consecutiveDotsRegex.test(filename) ||
    startEndDotRegex.test(filename)
  ) {
    // Invalid characters, consecutive dots, or start/end with a dot
    return false;
  }

  return true;
};