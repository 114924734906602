const appName = 'CMS Image Upload';
const log = (message: string) => {
  console.log(`${appName} | ${message}`);
};

const warning = (message: string) => {
  console.warn(`${appName} | ${message}`);
};

const error = (message: string, error: any) => {
  console.error(`${appName} | ${message}`, error);
};

export const logger = {
  error,
  log,
  warning,
};