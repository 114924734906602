import { useEffect, useState } from 'react';
import { Masthead } from 'retail-ui-library';

import Camera from '../Camera';
import ImageUploadForm from '../ImageUploadForm';
import pkg from '../../../package.json';

import './index.scss';

const App = () => {
  const [capturedImage, setCapturedImage] = useState('');
  const [acceptedImage, setAcceptedImage] = useState(false);

  useEffect(() => {
    PubSub.subscribe('camera.capture.image', (topic, data) => setCapturedImage(data));
    PubSub.subscribe('camera.capture.accept.image', (topic, accepted: boolean) => setAcceptedImage(accepted));

    return () => {
      PubSub.unsubscribe('camera.capture');
    };
  }, []);

  return (
    <div className='app' data-test-id='app'>
      <Masthead
        appName='CMS Image Upload'
        showLogoutButton={false}
        version={pkg.version}
      />

      <Camera capturedImage={capturedImage} />

      {capturedImage && acceptedImage && (
        <ImageUploadForm capturedImage={capturedImage} />
      )}
    </div>
  );
};

export default App;
