import { useEffect, useState } from 'react';
import PubSub from 'pubsub-js';
import { Button, ErrorModal, Input, LoadingModal, SuccessModal } from 'retail-ui-library';

import useConfig from '../../hooks/useConfig';
import { http } from '../../lib/http';
import { image } from '../../lib/image';
import { isValidFilename, isValidShopId } from '../../lib/validation';

import './index.scss';

const VALID_FILE_TYPE = 'image/png';

const ImageUploadForm = ({ capturedImage }: Props) => {
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [rawFilename, setRawFilename] = useState<string>('');
  const [validationErrors, setValidationErrors] = useState({
    shopId: '',
    imageName: '',
  });
  const [formValues, setFormValues] = useState({
    shopId: '',
    imageName: '',
  });

  const { config } = useConfig();

  useEffect(() => {
    if (formValues.shopId && formValues.imageName) {
      setRawFilename(image.constructFilename(formValues.imageName, formValues.shopId));
    }
  }, [formValues]);

  const onChange = (e: any) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async () => {
    setValidationErrors({
      shopId: '',
      imageName: '',
    });

    const invalidFields = {} as any;

    if (!isValidShopId(formValues.shopId)) {
      invalidFields.shopId = 'Not a valid shop number';
    }

    if (!isValidFilename(formValues.imageName)) {
      invalidFields.imageName = 'Invalid characters in filename';
    }

    if (Object.keys(invalidFields).length > 0) {
      setValidationErrors(invalidFields);
      return;
    }

    try {
      setLoading(true);

      const blob = await image.convertImageDataToBlob(capturedImage, VALID_FILE_TYPE);

      const { data: { url: presignedUrl } } = await http.get(`${config.getSignedUrlApiEndpoint}?size=${blob.size}&filename=${rawFilename}`);

      await http.put(presignedUrl, {
        body: blob,
        headers: {
          'Content-Type': VALID_FILE_TYPE,
        },
      }, 'text');

      setLoading(false);
      setShowSuccess(true);
    }
    catch (error) {
      setLoading(false);
      setErrorMessage(JSON.stringify(error));
      setShowError(true);
    }
  };

  const onReset = () => {
    window.location.reload();
  };

  const onDismissModal = () => {
    setShowSuccess(false);
    setRawFilename('');
    setFormValues({
      shopId: '',
      imageName: '',
    });
    PubSub.publish('camera.capture.accept.image', false);
    PubSub.publish('camera.capture.image', '');
    PubSub.publish('camera.start');
  };

  return (
    <div
      className='image-upload-form'
      data-test-id='image-upload-form'
      id='image-upload-form'
    >
      <div>
        <Input
          className='image-upload-form-input'
          dataTestId='shop-number'
          error={validationErrors.shopId}
          inputMode='numeric'
          label='Shop Number'
          maxLength={6}
          name='shopId'
          onChange={onChange}
          placeholder='Enter shop number'
          value={formValues.shopId}
        />

        <Input
          className='image-upload-form-input'
          dataTestId='image-name'
          error={validationErrors.imageName}
          label='Image Name'
          name='imageName'
          note={rawFilename}
          onChange={onChange}
          value={formValues.imageName}
        />
      </div>

      <div className='image-upload-form-buttons'>
        <Button
          dataTestId='image-upload-form-reset'
          isFullWidth
          isSecondary
          onClick={onReset}
          type='button'
        >
          Reset
        </Button>

        <Button
          dataTestId='image-upload-form-submit'
          isDisabled={!formValues.shopId || !formValues.imageName || !capturedImage}
          isFullWidth
          onClick={onSubmit}
          type='button'
        >
          Submit Image
        </Button>
      </div>

      {loading && <LoadingModal message={`Uploading file: ${rawFilename}`} />}

      {showError && (
        <ErrorModal
          message={`Shop ID: ${formValues.shopId}\nName: ${rawFilename}\nError: ${errorMessage}`}
          onOk={onDismissModal}
          title='Error!'
        />
      )}

      {showSuccess && (
        <SuccessModal
          message={`Shop ID: ${formValues.shopId}\nName: ${rawFilename}`}
          onOk={onDismissModal}
          title='Image Uploaded!'
        />
      )}
    </div>
  );
};

type Props = {
  capturedImage: string;
}

export default ImageUploadForm;
