import React, { createContext } from 'react';

import { AppConfig, EnvConfig } from '../../types';
import { devConfig } from '../../config/env/dev';
import { localConfig } from '../../config/env/local';
import { nonprodConfig } from '../../config/env/nonprod';
import { prodConfig } from '../../config/env/prod';

const ConfigProvider = ({ children }: Props) => {
  const config: AppConfig = {
    local: localConfig,
    dev: devConfig,
    nonprod: nonprodConfig,
    prod: prodConfig,
  };

  const env = process.env.REACT_APP_ENV ?? 'local';
  const envConfig: EnvConfig = config[env];

  console.log('Env:', env);

  return (
    <ConfigContext.Provider value={{
      config: envConfig,
    }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

type ConfigContextProps = {
  config: EnvConfig;
}

type Props = {
  children?: React.ReactNode;
}

export const ConfigContext = createContext<ConfigContextProps>({
  config: {} as EnvConfig,
});

export default ConfigProvider;
